.AnnotationCard {
  max-width: 1200px;
  width: 100%;
  flex-direction: row;
  align-items: center;
  display: flex;
  justify-content: space-between;

  &_Meta {
    &_Link {
      font-size: 0.9rem;
      color: #b3aeae;
      text-decoration: underline;
    }
  }

  &_Actions {
    flex-shrink: 0;
    display: flex;
    align-items: center;

    & > * {
      margin-left: 16px !important;
    }

    & > div {
      max-width: 150px !important;
    }
  }
  
  .TextContainer {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .Title {
    font-weight: bold;
    color: red;
  }
  
}
.AnnotationCardCluster {
  padding: 20px;
}