.PaperWidthFull {
  //width: 900px !important;
}
.PaperWidthSmall {
  max-width: 500px !important;
  max-height: 900px !important;
}
.ActionsCentered {
  justify-content: center !important;
}
.ActionProceedLight {
  border: 1px solid #eaeef4;
  color: #292d32;
  text-transform: none;
}