// Fonts
@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap');

// Variables
@import 'variables';

html,
body {
  font-family: Inter, Roboto, Graphik, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  margin: 0;
  padding: 0;
  overflow: hidden;
  height: 100%;
  width: 100%;
  font-size: 1rem;
  font-style: normal;
  //letter-spacing: -0.408px;
}

body input {
  font-family: Inter, sans-serif !important;
}

#wisevoice-root {
  height: 100%;
  width: 100%;
}

body {
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;

  #menu- {
    z-index: 20000 !important;
  }
}

#global-root {
  width: 100%;
  height: 100%;
  background-color: white;
}
#modal-root {
  button {
    font-family: Inter, sans-serif !important;
  }
}
.ui.selection.dropdown {
  font-family: Inter, sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 15px !important;
  line-height: 15px !important;
  letter-spacing: -0.408px;
}
.ui.dropdown .menu > .item {
  font-family: Inter, sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 15px !important;
  line-height: 15px !important;
  letter-spacing: -0.408px;
}
.ui.selection.dropdown:active {
  border-color: #969090;
}
.ui.selection.dropdown:focus {
  border-color: #969090;
}
.ui.selection.active.dropdown {
  border-color: #969090;
}
.ui.selection.active.focus {
  border-color: #969090;
}

.ui.selection.active.dropdown:hover {
  border-color: #969090;
}
.ui.selection.active.dropdown .menu {
  border-color: #969090;
}
.ui.selection.active.dropdown:hover .menu {
  border-color: #969090;
}

.ui.mini.modal .header {
  font-family: Inter, sans-serif !important;
  font-style: normal;
  font-size: 15px !important;
  line-height: 15px !important;
  letter-spacing: -0.408px;
}
.ui.mini.modal .content {
  font-family: Inter, sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 15px !important;
  line-height: 15px !important;
  letter-spacing: -0.408px;
}

.ui.mini.modal .actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.ui.mini.modal .actions button {
  text-transform: uppercase;
  font-weight: bold;
}

.MuiCheckbox-root {
  color: #56595f !important;
}
// .MuiButton-label {
//   font-family: Inter, sans-serif !important;
// }
.MuiTableCell-head {
  font-weight: bold !important;
}
input:focus-visible {
  outline: solid 2px #000;
}
.ui.page.modals {
  z-index: 1401;
}

.languageDropdown {
  margin: 12px 0 12px 20px;
  padding: 10px 31px 10px 15px !important;
  min-height: 30px !important;
  min-width: 160px !important;
}
