.ReplySuggestionContainer {
  display: flex;
  justify-content: flex-end;
  margin: 9px 10px 6px 2px;
  flex-wrap: wrap;
}
.ReplySuggestionElement {
  padding: 10px;
  border: solid 1px #2a86ff;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 9px;
  margin-bottom: 9px;
  //line-height: 18px;
}