.Annotations {
  height: 100%;
  padding: 32px;
  position: relative;
  &_Tabs {
    display: flex;
    justify-content: center;
    margin-top: 16px;
  }
  &_TabsRoot {
    flex-grow: 1;
  }

  &_ScrollContainer {
    height: calc(100% - 180px);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    padding: 6px;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
  }
  &_ScrollContainer.clusters_container {
    height: calc(100% - 130px);
  }
  &_Loader {
    position: absolute;
    background: #fff;
    top: 170px;
    width: calc(100% - 64px);
    height: calc(100% - 240px);
    z-index: 1000;
    opacity: 0.6;
  }
  &_Container {
    flex: 1;
    display: flex;
    flex-direction: column;
    &_Filters {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      &_Main {
        display: flex;
        align-items: flex-end;
        & > * {
          margin-right: 32px;
        }
      }
    }
  }
}
