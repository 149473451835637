.ConversationStreamHeader {
  height: 76px;
  position: relative;
  flex-shrink: 0;
  min-height: 76px;
  padding-left: 15px;
  padding-right: 15px;
  border-bottom: 1px solid #eef3ff;

  background-color: #fff;

  box-sizing: border-box;
  align-items: center;
  z-index: 100;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .header-icon {
    display: flex;
    flex-shrink: 2;
    overflow: clip;
    min-width: 5.5em;
  }

  .InfoIcon {
    padding: 3px;
    margin-right: 8px;
    margin-left: 5px;
  }

  .close-conversation {
    min-width: 20px;
    border: 1px solid #eaeef4;
    border-radius: 10px;
    color: #292d32;
    padding: 6px 19px 5px 19px;


    .close-conversation-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      text-transform: none;
      height: 28px;
    }
  }

  .header-conversation-card-inbox-wrapper {
    text-align: right;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    min-width: 18rem;
    align-self: center;

    .conversation-card-title-text {
      padding-bottom: 2px;
      font-weight: 400;
      font-size: 18px;

      white-space: nowrap !important;
      overflow: hidden !important;
      text-overflow: ellipsis !important;
      display: block !important;
    }

    .MuiInputLabel-root {
      color: #d5dde8 !important;
      font-family: DM Sans;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: -0.208px;
    }

    .MuiFormLabel-root {
      top: 4px;
      margin-left: 2px;
    }

    .MuiFormControl-root {
      .MuiInputBase-root {
        background-color: white;
        border: '1px solid #eaeef4';
        border-radius: 10px;
      }
    }

    .select-label {
      flex-shrink: 0;
      width: 12px;
      right: 7px;
    }
  }

  .header-conversation-card-inbox-wrapper-isMobile {
    text-align: right;
    margin-left: 10px !important;
    display: flex;
    flex-direction: row;

    overflow-y: auto;
    justify-content: space-between !important;
    align-content: center;
  }
}

.ConversationStreamHeaderIsMobile {
  padding: 15px 0 22px 0;
}
