.InfoCard {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: #ffffff;
  border-bottom: 1px solid rgba(217, 217, 217, 1);
  min-height: 50px;
  padding: 5px 16px 10px 16px;

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .title {
      margin: 19px 0px 18px 0px;

      font-weight: 600;
      line-height: 18px;
    }

    .collapsible-button {
      display: flex;
      justify-content: flex-end;
    }
  }
}

.InfoCard.MediaAndFilesWidget {
  border: none;
}
.InfoCard.LatestConversationsCard {
  padding: 0 !important;
  .header {
    padding-left: 16px;
  }
}